@import "_variables.scss";

html{
  background-color: $color-secondary;
  background-image: url(../images/background.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 100vh;
}
body{
  color: #333;
  background: none;
}



// RESETS

.collectorElement .item p, .aggregatorElement p{
  line-height: 1.5;
}



#pageContentContainer {
  padding-top: 60px;
}



// Navigation
#topNav{
  font-family: $primary-font;
  position: relative;
  font-size: rem(13px);
  .nav{
    li{list-style: none;}
    background: $color-primary;
    padding: 0;
    font-weight: 500;
    letter-spacing: 1px;
    border-bottom: 5px solid #C8BF9E;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:after{
      content: "";
      display:block;
      position: absolute;
      background-image: url("../images/the-prospect-way.svg");
      background-size:contain;
      background-repeat: no-repeat;
      width: 250px;
      height: 100%;
      top: 93%;
      left: 50%;
      transform: translateX(-50%);
      z-index:30;
    }
  }
}

.split-nav{
  width:100%;
  display: flex;
  flex-direction: row;
  > li{
    text-align: center;
    width: 100%;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      transform: translateY(-100%);
      top: 0;
      left: 0;
      width:100%;
      height: 0;
      background-color: #C8BF9E;
      transition: height .1s ease-out;
      z-index: -2;
    }
    &.selected, &:hover{
      &:before{
        height: 10px;
      }
    }
    & + li{border-left: 1px solid darken($color-primary, 10%);}
    a{
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      padding: 1em;
      color: #FFF;
      text-transform: uppercase;
      position: relative;
    }
  }
}
// Mid Nav Item
.nav-mid{
  width: auto;
  min-width: $emblem-width;
  border-left: 1px solid darken($color-primary, 10%);
  border-right: 1px solid darken($color-primary, 10%);
}

// Navigation Dropdowns
.dropdown {
  position: absolute;
  top: 100% !important;
  left: 0 !important;
  width: 100%;
  min-width: 150px;
  list-style: none;
  background-color: $color-primary !important;
  border: none !important;
  border-top: 1px solid darken($color-primary, 10%);
  z-index: 1000;
  li{ border: none;}
  ul > li, & > li{
    border-top: 1px solid  darken($color-primary, 10%);
    &:hover{background-color: darken($color-primary, 15%);}
    & .disabled{
      border-top: 1px solid  darken($color-primary, 30%);
    }
  }
  ul{
    background-color: darken($color-primary, 5%) !important;
  }
}
.nav .disabled{
  background-color: #333;
}
.nav li > ul{
    display: none;
}
.nav li:hover > ul{
    display: block;
}
#topNav ul .dropdown-disclosure {
    background-position: 0 -22px;
    margin: 0;
    margin-left: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: auto;
    transition: margin-left $transition-n;
}
#topNav ul li:hover a{
  &:hover .dropdown-disclosure{
    margin-left: 5px;
  }
}
#topNav ul li:hover a .dropdown-disclosure{
    background-position: 0 -22px;
}
#topNav .dropdown li {
  > ul{
    position: absolute;
    top: 0;
    right: auto;
    left: 100%;
    width: 100%;
    border: none;
    border-left: 1px solid darken($color-primary, 10%);
  }
  .right {
    right: 100%;
    left: auto;
    border: none;
    border-right: 1px solid darken($color-primary, 10%);
  }
}





// Trash
#topNav .nav #top_nav_node_page_node_2000199{
  position: absolute;
  display: block;
  top: -150%;
  left: 0;
  width: auto;
  background: #333;
  border: none;
  transition: color $transition-n;
  &:hover{
    background: #666;
  }
  &:before{
    display: none;
  }
  a{
    display: inline-block;
    color: #FFF;
    text-transform: uppercase;
    padding: 1em;
    &:before{
      content:'';
      display: inline-block;
      background-image: url("../images/trash.svg");
      background-size:contain;
      background-repeat:no-repeat;
      padding: .75em;
      margin-top: -10px;
      transform: translateY(3px);
    }
  }
}


// Site Header
#siteHeader{
  height: auto;
  min-height: 150px;
  display: flex;
  > h1{ display: none;}
}
$logo-transit: .2s ease-in-out;
.site-logo{
  background-image: url("../images/emblem.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 1em;
  height: 1em;
  margin: 0 auto;
  align-self: flex-end;
  padding: 9em;
  transform: translateY(2em);
  z-index: 5;
  // -webkit-filter: drop-shadow(0px 10px 10px rgba(0,0,0,.5));
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  &:hover .shine{
    opacity: 1;
  }
}

.shine{
  display: block;
  position: relative;
  width:100%;
  background-image:url("../images/shine-new-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity $logo-transit;
  position: absolute;
  width: 228px;
  height: 228px;
  top: 0;
  left: 0;
  border-radius: 100%;
}

// Custom Slideshow
.custom-slideshow{
  font-family: $primary-font;
  .user_mode & {margin-top: -72px;}
  .mediaSlider{ margin: 0 0 0 -15px; }
  .sn-media-slider .slides .slide .media-wrapper img{
    width: 100% !important;
  }
  .sn-media-slider .slider-pagination .paging-item {
    height: 10px;
    width: 50px;
    background-color: $color-primary;
    transition: background-color $transition-n;
    &.flex-active{
      background-color: $color-secondary;
    }
    &:hover{
      background-color: $color-secondary;
    }
    + .paging-item{
      margin-left: 10px;
    }
  }
  .sn-media-slider .slider .slide-title{
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1;
    font-size: rem(50px);
    margin: 1rem 0;
  }
  .sn-media-slider .slider .slide-description{
    font-family: $secondary-font;
    font-weight: 400;
    font-style: italic;
    font-size: rem(30px);
    margin: 1rem 0;
  }
  .sn-media-slider .slides .slide .slide-overlay{
    padding: 5%;
  }
  .sn-media-slider .slider.bottom-center-paging{
    margin-bottom: 0;
  }
  .sn-media-slider .slider.bottom-center-paging .slider-pagination{
    top: 90%;
    z-index: 999;
    bottom: initial;
  }
  .slide-link{
    font-family: $primary-font;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    color: #FFF;
    border-radius: 3px;
    background: $color-secondary;
    box-shadow: inset 0px -3px 0px darken($color-secondary, 5%), 0 0 5px rgba(0,0,0,.5);
    padding: rem(9px) rem(12px);
    transition: all $transition-n;
    &:not([href]){display: none;}
    &:after{
      content: "Learn More";
      display: block;
      position:relative;
    }
    &:hover{
      box-shadow: inset 0px -5px 0px darken($color-secondary, 5%), 0 0 2px rgba(0,0,0,.5);
    }
  }
}

// Custom Callouts
.full-width-content{
  .user_mode &{
    margin-left: -15px;
    margin-right: -17px;
  }
}
.custom-callout{
  font-family: $primary-font;
  .pageElement{
    margin-right:0;
  }
  .sn-call-to-action{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 300px;
    img{
      display: none;
    }
  }
  .sn-call-to-action .sn-call-to-action-overlay-inner{
    display: block;
  }
  .sn-call-to-action .sn-call-to-action-overlay-text{
    display: block;
    text-align: left;
  }
  .sn-call-to-action .sn-call-to-action-title{
    font-size: rem(28px);
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.2;
    &:after{
      content:"";
      display: block;
      position: relative;
      margin-top:1em;
      width: 10%;
      height: 5px;
      border-top: 1px solid $color-primary;
      border-bottom: 1px solid $color-primary;
      transition: all .2s .1s ease-in-out;
    }
  }
  .sn-call-to-action .sn-call-to-action-overlay{
    background-color: transparent;
    transition: background-color $transition-n;
    &:hover{
      background-color: rgba( $color-primary, .75);
      .sn-call-to-action-title:after{
        border-color: $color-secondary;
        width:20%;
      }
    }

  }
}




// Headers
%special-header{
  background: #221e1f;
  color: #FFF;
  font-family: $primary-font;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left:.5em;
  font-size:rem(16px);
  background-image: url('../images/emblem-star.svg');
  background-repeat: no-repeat;
  background-position: 99% center;
  background-size: 2.5em;
}
.special-header{
  @extend %special-header;
}
.textBlockElement h3,
.aggregatorElement h3,
.mailSubscribeElement h3,
.reportTableElement h3,
.tableElement h3,
.pollElement h3{
  @extend %special-header;
}


// Horizontal Rule
.custom-divider{
  .hrElement{
    width:75%;
    position: relative;
    margin: 0 auto 0 auto !important;
    border-color: $color-secondary;
    &:before, &:after{
      content: "";
      display: inline-block;
      position:absolute;
      top:0;
      transform: translateY(-50%);
      width: 1px;
      height: 9px;
      border-left: 1px solid $color-secondary;
      border-right: 1px solid $color-secondary;
    }
    &:before{ left:-2px; }
    &:after{ right:-2px; }
   + span{
      width: 70%;
      height: 30px;
      margin: -30px auto 30px auto;
      box-shadow: 0px 8px 15px -10px rgba(0,0,0,.5);
   }
  }
}


// Social Media
.custom-icons{
  .sn-social-media-list{
    display: flex;
    justify-content: center;
  }
  .sn-social-media-icon{
    position: relative;
    + .sn-social-media-icon{margin-left:.5em;}
    &:before{
      color: $color-secondary;
      transition: color $transition-n;
    }
    &:after{
      content:"";
      width: 4rem;
      height: 4rem;
      display: block;
      background:
      url(../images/social-shape-top.svg) no-repeat right top,
      url(../images/social-shape.svg) no-repeat;
      // linear-gradient( to top,  $color-primary, $color-primary 3px, transparent 3px, transparent 100%) no-repeat,
      // linear-gradient( to right,  $color-primary, $color-primary 3px, transparent 3px, transparent 100%) no-repeat,
      // linear-gradient( to bottom,  $color-primary, $color-primary 3px, transparent 3px, transparent 100%) no-repeat,
      // linear-gradient( to left,  $color-primary, $color-primary 3px, transparent 3px, transparent 100%) no-repeat;
      background-color: #FFF;
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      transition: background-color $transition-n;
    }
  }
  .sn-social-media-list.dark .sn-social-media-icon{
    background: transparent;
    border-radius: 0;
    &:hover{
      &:before{color: #FFF;}
      &:after{background-color: $color-primary;}
    }
  }
}

// Footer
#siteFooter{
  background-color: lighten($color-primary, 5%);
  padding: 2em 1em 3em 1em;
  box-sizing: border-box;
  color: $color-secondary;
  .nav-footer{
    display:flex;
    justify-content: center;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    li{
      padding-left: .5em;
      > ul {display: none;}
      + li{border-left: 2px solid $color-secondary;}
      a{
        padding: .5em 1em;
        color: $color-secondary;
        line-height: 1;
        transition: color $transition-n;
        &:hover{color:#FFF;}
      }
      &#top_nav_node_page_node_2000199{
        display: none;
      }
    }
  }

  ul:nth-of-type(2){
    text-align: center;
    padding-top:0;
    li a{
      color: $color-secondary;
      text-decoration: underline;
      &:hover{
        color:#FFF;
      }
    }
    li#poweredByNGIN{
      width:100%;
      padding-top: 1em;
    }
    @extend %clearfix;
  }
}



// Subnav
.subNav{
  .user_mode &{border-right: 1px solid $color-primary;}
  h4{
    text-transform: uppercase;
  }
  ul.parents, ul.children{
    li > a{
      transition: color $transition-n;
      &:hover{ color: $color-primary; }
    }
  }
}
