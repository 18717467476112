$type-color: #333;
$primary-font: 'Maven Pro', sans-serif;
$secondary-font: 'Josefin Slab', serif;
$type-base: 12px;
$emblem-width: 204px;

// COLORS
$color-primary: #b2aa7e;
$color-secondary: #004B2C;

// Transitions
$transition-n: .1s ease-out;

// Layout
$layout-max: 1000px;

@function em($target, $context: $type-base) {
  @return ($target / $context) * 1em;
}
@function rem($target) {
  @return ($target / $type-base) * 1rem;
}
@mixin font-smoothing{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%clearfix{
  zoom: 1;
  &:after, &:before{
    content: "";
    display: table;
  }
  &:after{
    clear: both;
  }
}
